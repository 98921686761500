import { Link } from 'react-router-dom'
import Image from './image.tsx'
import type { SliderCard as SliderCardType } from '#types/graphql.js'

type SliderCardProps = {
	card: SliderCardType
}

export default function SliderCard({ card }: SliderCardProps) {
	const title = card?.title
	const subtitle = card?.subtitle
	const uri = card?.uri
	const image = card.image

	return (
		<Link to={uri}>
			<div className="group flex w-[400px] flex-col space-y-[20px]">
				<div className="relative h-[400px] w-[400px] transform overflow-hidden transition-all duration-300 ease-in-out group-hover:scale-90">
					{image[0] ? (
						<Image
							img={image[0]}
							className="absolute h-full w-full transform bg-contain bg-center transition-all duration-300 ease-in-out group-hover:scale-150"
						/>
					) : (
						<Image
							img={{
								alt: title,
								height: 40,
								width: 40,
								title: title,
								uid: '',
								url: 'data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27768%27 height=%27768%27 style=%27background:%2300362e%27 /%3E',
							}}
							className=""
						/>
					)}
				</div>

				{title && subtitle && (
					<>
						<div className="flex flex-col space-y-[5px]">
							<h4 className="text-2xl font-light leading-[45px] tracking-wide text-green-300">
								{title}
							</h4>
							<p className="text-base font-light leading-7 text-gray-100">
								{subtitle}
							</p>
						</div>
						<div className="mt-4 w-[70px]  border-b-2 border-copper-100"></div>
					</>
				)}
			</div>
		</Link>
	)
}
